@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.App {
  text-align: left;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: flex-start; /* Start items from the top */
  font-size: calc(-3px + 2vmin);
  color: #333;
  padding-left: 20px; /* Add some space from the left edge */
}

h1 {
  margin-bottom: 20px;
}

.skills-list {
  width: 100%; /* Set to 100% for full width */
  border: 1px solid #fff;
}

.skill-item,
.header-row {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #f0f2f5; /* Added this line */
  font-weight: bold;
}

.header-row {
  font-weight: bold;
  background-color: #fff;
  border-top: none; /* Remove the top border for the header row */
}

.rank,
.header-row > span:nth-child(1) {
  flex: 1;
  font-weight: bold;
}

.skill-item .rank {
  /* New style for the actual rank numbers */
  font-weight: normal;
  color: #716f6f; /* light gray color */
}

.skill-name,
.header-row > span:nth-child(2) {
  flex: 3;
}

/* Make sure other columns also have matching flex values. For simplicity, let's assume each of the other columns also takes up flex of 1 */
.header-row > span:not(:nth-child(1)):not(:nth-child(2)),
.skill-item > span:not(.rank):not(.skill-name) {
  flex: 1;
}
.skill-icon {
  height: 20px; /* Adjust the height as required */
  width: auto;
  margin-right: 8px; /* Add some spacing between the icon and skill name */
}
.skills-list a {
  text-decoration: none;
  color: inherit;
}

.skill-item:hover {
  background-color: #f5f5f5; /* or any other color you prefer for hover effect */
}

.red {
  color: #d8494a;
}

.green {
  color: #5cc489;
}
.rank-icon {
  height: 24px; /* Adjust the height as required */
  width: auto;
  margin-right: 4px;
  /* Add some spacing between the icon and text */
  vertical-align: middle; /* Aligns the image vertically in line with the text */
}
.home-link {
  text-decoration: none;
  color: inherit; /* this will ensure it keeps the current color of its parent/container */
}
.details-wrapper {
  display: flex;
  width: 100%;
}

.skill-details,
.news-section,
.courses-section {
  flex: 1;  /* This makes each section take 1/3 of the page */
  padding: 10px; /* Adding a bit of padding to separate the contents within the sections */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}

.news-section,
.courses-section {
  border-left: 1px solid #e5e5e5; /* Adds a vertical divider between sections */
}
.details-wrapper {
  display: flex;
  width: 100%;
}

.skill-details,
.news-section,
.courses-section {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.news-section,
.courses-section {
  border-left: 1px solid #e5e5e5;
}

/* Styling for news articles */
.news-article {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

.news-image {
  max-width: 100%;
  margin-bottom: 10px;
}

.news-article h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.news-article a {
  color: #0077cc;
  text-decoration: none;
}

.news-article a:hover {
  text-decoration: underline;
}

.courses-list {
  list-style-type: none;
  padding: 0;
}

.courses-list li {
  margin-bottom: 15px;
}

.courses-list a {
  color: #0077cc;
  text-decoration: none;
  font-size: 18px;
}

.courses-list a:hover {
  text-decoration: underline;
}
